
// CSS
import './MendoCann.css';


export default function MendoCann() {
    return (
        <div className="mendocann">
            {/* <h1>Mendocann</h1> */}
            <p> Esta página está en construcción, pronto estará disponible. </p>
        </div>
    )
}